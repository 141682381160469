import * as types from "../constants";

export function setUser(user) {
    return {
        type: types.SET_USER,
        user: user,
    };
}

export function setUsers(users) {
    return {
        type: types.SET_USERS,
        users: users,
    };
}

export function setUserProfile(profile) {
    return {
        type: types.SET_USER_PROFILE,
        profile: profile,
    };
}

export function setTemplate(template) {
    return {
        type: types.SET_TEMPLATE,
        template: template,
    };
}

export function setUserTimerRunning(isRunning) {
    return {
        type: types.SET_USER_TIMER_RUNNING,
        isRunning,
    };
}

export function updateUserManager(userId, managerId) {
    return {
        type: types.UPDATE_USER_MANAGER,
        userId,
        managerId,
    };
}

export function setFullScreenEmail(e, replyEnabled = false, addBarButtons = false) {
    return {
        type: types.SET_FULL_SCREEN_EMAIL,
        e,
        replyEnabled,
        addBarButtons,
    };
}

export function showCaseActionDetails(caseActionId) {
    return {
        type: types.SHOW_CASE_ACTION_DETAILS,
        caseActionId,
    };
}

export function addAcceptedEmailTaskSid(taskSid) {
    return {
        type: types.ADD_ACCEPTED_EMAIL_TASK_SID,
        taskSid,
    };
}

export function removeAcceptedEmailTaskSid(taskSid) {
    return {
        type: types.REMOVE_ACCEPTED_EMAIL_TASK_SID,
        taskSid,
    };
}

export function setActiveFlexEmailTaskSid(taskSid) {
    return {
        type: types.SET_ACTIVE_FLEX_EMAIL_TASK_SID,
        taskSid,
    };
}

export function openFileViewer(
    file,
    caseId = null,
    attachment = null,
    isEmailPreview = false,
    showCopyToLiveDocument = true,
    uploadFile = null
) {
    return {
        type: types.OPEN_FILE_VIEWER,
        file,
        caseId,
        attachment,
        isEmailPreview,
        showCopyToLiveDocument,
        uploadFile,
    };
}

export function closeFileViewer() {
    return {
        type: types.CLOSE_FILE_VIEWER,
    };
}

export function setCheckingUserSession(isChecking) {
    return {
        type: types.SET_CHECKING_USER_SESSION,
        isChecking,
    };
}

export function setUserAdviceTypes({ userId, adviceTypes }) {
    return {
        type: types.SET_USER_ADVICE_TYPES,
        payload: {
            userId,
            adviceTypes,
        },
    };
}

export function setSeekAdviceMiscActivityId(id) {
    return {
        type: types.SET_SEEK_ADVICE_MISC_ACTIVITY_ID,
        id
    }
}

export function setRaiseCaseRequestId(id) {
    return {
        type: types.SET_RAISE_CASE_REQUEST_ID,
        id
    }
}