import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    IconButton,
    Typography,
    Grid,
    Switch,
    makeStyles,
    CircularProgress,
    Select,
    MenuItem,
    FormControl,
    Button,
    TextField,
    Box,
    Popover,
    FormControlLabel,
    Radio,
    RadioGroup,
    Chip,
    Checkbox,
} from "@material-ui/core";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { bindActionCreators } from "redux";
import { actionCreators } from "../redux/reducers/caseReducer";
import { useHistory } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import useCaseActionCategories from "../hooks/queries/useCaseActionCategories";
import useCaseActionTypes from "../hooks/queries/useCaseActionTypes";
import useAddCaseAction from "../hooks/mutations/useAddCaseAction";
import useUpdateCaseAction from "../hooks/mutations/useUpdateCaseAction";
import { setSnackAction } from "../redux/actions/snackActions";
import { addCaseEventAction } from "../redux/actions/caseActions";
import caseActionReminderMetrics from "../constants/caseActionReminderMetrics";
import { ArrowDropDown as AddAlertIcon, CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import useFilteredAdvisers from "../hooks/useFilteredAdvisers";
import useFilteredTeams from "../hooks/useFilteredTeams";
import { getDstCorrectedDateString } from "../helpers/dateHelpers";

const uncheckedIcon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: "center",
    },
    fullWidthField: {
        width: "100%",
    },
    fieldWithButton: {
        width: "80%",
    },
    avatar: {
        marginRight: theme.spacing(3),
    },
    completeButton: {
        marginLeft: theme.spacing(3),
    },
    addButton: {
        borderRadius: 0,
        paddingRight: 0,
        "&:hover": {
            background: "none",
        },
    },
    alertNone: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
    },
    popOver: {
        padding: theme.spacing(2),
        border: "3px solid #e0e0e0",
    },
    contentPopOver: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(3),
    },
    alertBox: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        border: "1px solid #C4C4C4",
        borderRadius: theme.spacing(1),
        "&:hover": {
            border: "1px solid black",
        },
        paddingTop: theme.spacing(1),
    },
    advisersBox: {
        padding: theme.spacing(2),
    },
    radioButton: {
        margin: theme.spacing(2),
    },
    chip: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        backgroundColor: "#F4F2FE",
        marginRight: theme.spacing(2),
    },
    caseLink: {
        backgroundColor: "#F4F2FE",
    },
    bottomButtons: {
        marginRight: theme.spacing(4),
    },
    checkboxSelect: {
        marginRight: theme.spacing(2),
    },
    selectedChip: {
        backgroundColor: "#F4F2FE",
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const CaseActionDialog = ({ open, setOpen, caseId, action, setAction, isET, getCase }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { cases } = useSelector((state) => state.caseReducer);
    const { userProfile } = useSelector((state) => state.userReducer);
    const filteredAdvisers = useFilteredAdvisers();
    const caseDescription = cases[caseId]?.caseSummary?.description || action.caseDescription;
    const buttonRef = useRef(null);
    const [alerts, setAlerts] = useState();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const alertOpen = Boolean(anchorEl);
    const [metric, setMetric] = React.useState(null);
    const [amt, setAmt] = React.useState("");
    const [advisers, setAdvisers] = React.useState(null);

    const addCaseActionToHistory = (a) => dispatch(addCaseEventAction(caseId, a));

    const {
        data: caseActionCategories,
        isLoading: isCategoriesLoading,
        isError: isCategoriesError,
        error: categoriesError,
    } = useCaseActionCategories({ dialogOpen: open });
    const {
        data: caseActionTypes,
        isLoading: isTypesLoading,
        isError: isTypesError,
        error: typesError,
    } = useCaseActionTypes({
        dialogOpen: open,
        caseActionCategoryId: action.categoryId,
    });
    //const caseActionTypes = [ {id: 26, description: "Agree list of Issues"} ]

    const { filteredTeams, isLoading: isTeamsLoading, isError: isTeamsError, error: teamsError } = useFilteredTeams({ dialogOpen: open });

    const {
        mutate: addCaseAction,
        isLoading: isAddCaseActionLoading,
        isError: isAddCaseActionError,
        error: addCaseActionError,
        isSuccess: isAddCaseActionSuccess,
    } = useAddCaseAction();
    const {
        mutate: updateCaseAction,
        isLoading: isUpdateCaseActionLoading,
        isError: isUpdateCaseActionError,
        error: updateCaseActionError,
        isSuccess: isUpdateCaseActionSuccess,
    } = useUpdateCaseAction();

    const handleAddAlertClick = () => {
        setAnchorEl(buttonRef.current);
    };

    const addAlert = () => {
        if (action?.reminders && action?.reminders?.filter((c) => c.amt == amt && c.metric == metric).length > 0) return;
        if (metric != null && amt != null && amt != "") {
            setAlerts({ metric, amt, metricTag: metric });
            handleAddAlertClose();
        }
    };

    const handleAddAlertClose = () => {
        setAnchorEl(null);
        setMetric(null);
        setAmt("");
    };

    const handleClose = () => {
        setAction({});
        setAlerts(null);
        setAdvisers(null);
        setOpen(false);
    };

    const selectCase = async (caseId) => {
        await getCase(caseId, history);
    };

    const handleRemoveAlert = (alert) => {
        if (alert.id) {
            setAction({
                ...action,
                reminders: action.reminders.filter((c) => c.id !== alert.id),
            });
        } else {
            setAction({
                ...action,
                reminders: action.reminders.filter((c) => !(c.metric == alert.metric && c.amt == alert.amt)),
            });
        }
    };

    const onAdvisersChange = (e, advisersSelected) => {
        setAction({
            ...action,
            teamId: "",
            advisers: advisersSelected,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let actionToSave = {
            ...action,
            caseId,
            due: getDstCorrectedDateString(new Date(action.due)),
            adviserIds: action.advisers?.map((u) => u.userId),
        };

        if (e.nativeEvent.submitter.name === "complete") {
            actionToSave.completedById = userProfile.userId;
            actionToSave.completedOn = getDstCorrectedDateString(new Date());
        }

        if (action.id)
            updateCaseAction(
                { caseAction: actionToSave },
                {
                    onSuccess: (data) => {
                        if (data.completedOn != null && cases[caseId])
                            addCaseActionToHistory({
                                adviserId: data.completedById,
                                itemType: "Action",
                                caseActionId: action.id,
                                eventTime: data.completedOn,
                                direction: 2,
                                person: data.completedBy,
                                actionCategory: data.category,
                                actionType: data.type,
                                actionSummary: data.summary,
                            });
                    },
                }
            );
        else {
            actionToSave.createdById = userProfile.userId;
            addCaseAction({ caseAction: actionToSave });
        }
    };

    useEffect(() => {
        if (isAddCaseActionError) dispatch(setSnackAction(`There was an error adding the action: ${addCaseActionError?.message}`, "error"));
    }, [isAddCaseActionError]);

    useEffect(() => {
        if (isUpdateCaseActionError) dispatch(setSnackAction(`There was an error updating the action: ${updateCaseActionError?.message}`, "error"));
    }, [isUpdateCaseActionError]);

    useEffect(() => {
        if (isAddCaseActionSuccess) {
            handleClose();
            dispatch(setSnackAction("Successfully added new action!", "success"));
        }
    }, [isAddCaseActionSuccess]);

    useEffect(() => {
        if (isUpdateCaseActionSuccess) {
            handleClose();
            dispatch(setSnackAction("Successfully updated the action!", "success"));
        }
    }, [isUpdateCaseActionSuccess]);

    useEffect(() => {
        if (isET && caseActionCategories?.length && !action?.id && open)
            setAction({
                ...action,
                typeId: "",
                categoryId: caseActionCategories.filter((c) => c.isET)[0]?.id || "",
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isET, caseActionCategories, open]);

    useEffect(() => {
        if (action.advisers) {
            setAdvisers(
                filteredAdvisers
                    .filter((a) => action.advisers.map((d) => d.userId).includes(a.userId))
                    .map((t) => ({
                        userId: t.userId,
                        name: t.name,
                    }))
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action.advisers]);

    useEffect(() => {
        if (alerts) {
            if (action?.reminders) {
                setAction({
                    ...action,
                    reminders: [...action?.reminders, alerts],
                });
            } else {
                setAction({ ...action, reminders: [alerts] });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alerts]);

    const defaultTypeValue = useMemo(() => {
        return caseActionTypes?.find((option) => option.id === action.typeId) || null;
    }, [caseActionTypes, action.typeId]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle onClose={handleClose}>Create an Action</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Box mb={6}>
                        <Button className={classes.caseLink} variant="contained" onClick={() => selectCase(caseId)}>
                            Case #{caseId}
                        </Button>
                        {!!caseDescription && <Typography variant="subtitle1">Case description: {caseDescription}</Typography>}
                    </Box>

                    <Grid container spacing={3} justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={3}>
                            <Typography>Importance:</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Switch
                                checked={action.important || false}
                                onChange={(e) =>
                                    setAction({
                                        ...action,
                                        important: e.target.checked,
                                    })
                                }
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={3}>
                            <Typography>Category:</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            {isCategoriesLoading && <CircularProgress />}
                            {isCategoriesError && <Typography>{categoriesError?.message || "Failed to load categories"}</Typography>}
                            {caseActionCategories && (
                                <FormControl required className={classes.fullWidthField} variant="outlined">
                                    <Select
                                        value={action.categoryId || ""}
                                        onChange={(e) =>
                                            setAction({
                                                ...action,
                                                typeId: "",
                                                categoryId: e.target.value,
                                            })
                                        }
                                        displayEmpty
                                        data-cy="select_category"
                                    >
                                        <MenuItem value="" disabled>
                                            Select category
                                        </MenuItem>
                                        {caseActionCategories.map((c) => (
                                            <MenuItem value={c.id} key={c.id} data-cy={`category-${c.id}`}>
                                                {c.description}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} justifyContent="flex-start" alignItems="flex-start" data-cy="select_action_type">
                        <Grid item xs={3}>
                            <Typography>Action Type:</Typography>
                        </Grid>
                        <Grid item xs={9} data-cy="action_type">
                            {isTypesLoading && <CircularProgress />}
                            {isTypesError && <Typography>{typesError?.message || "Failed to load action types"}</Typography>}
                            <Autocomplete
                                key={defaultTypeValue}
                                defaultValue={defaultTypeValue}
                                getOptionSelected={(option, value) => option.id === value}
                                onChange={(e, value) =>
                                    setAction({
                                        ...action,
                                        typeId: value?.id,
                                    })
                                }
                                renderInput={(params) => <TextField {...params} required variant="outlined" />}
                                options={
                                    (caseActionTypes || []).sort((a, b) => (a.description > b.description ? 1 : b.description > a.description ? -1 : 0)) || []
                                }
                                getOptionLabel={(option) => option.description || ""}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={3}>
                            <Typography>Summary:</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                required
                                variant="outlined"
                                value={action.summary || ""}
                                onChange={(e) =>
                                    setAction({
                                        ...action,
                                        summary: e.target.value,
                                    })
                                }
                                className={classes.fullWidthField}
                                data-cy="action_summary"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={3}>
                            <Typography>Due:</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <KeyboardDateTimePicker
                                format="dd/MM/yy HH:mm"
                                ampm={false}
                                value={action.due || null}
                                onChange={(date) =>
                                    setAction({
                                        ...action,
                                        due: date,
                                    })
                                }
                                inputVariant="outlined"
                                className={classes.fullWidthField}
                                required
                                data-cy="action_date"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={3}>
                            <Typography>Alert:</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <div>
                                <Box display="flex" alignItems="flex-start" className={classes.alertBox}>
                                    <Box flexGrow={1} display="flex" flexWrap="wrap" alignItems="center">
                                        {action.reminders?.length > 0 ? (
                                            action.reminders.map((c, index) => (
                                                <Box mb={1} key={index}>
                                                    <Chip
                                                        className={classes.chip}
                                                        label={
                                                            c.metric == caseActionReminderMetrics.DAYSBEFORE.tag
                                                                ? c.amt +
                                                                  " " +
                                                                  (c.amt == 1
                                                                      ? caseActionReminderMetrics.DAYSBEFORE.labelSingular
                                                                      : caseActionReminderMetrics.DAYSBEFORE.labelPlural)
                                                                : c.amt +
                                                                  " " +
                                                                  (c.amt == 1
                                                                      ? caseActionReminderMetrics.HOURSBEFORE.labelSingular
                                                                      : caseActionReminderMetrics.HOURSBEFORE.labelPlural)
                                                        }
                                                        onDelete={() => {
                                                            handleRemoveAlert(c);
                                                        }}
                                                    />
                                                </Box>
                                            ))
                                        ) : (
                                            <Box display="flex" alignItems="center" className={classes.alertNone}>
                                                <Typography>None</Typography>
                                            </Box>
                                        )}
                                    </Box>
                                    <Box>
                                        <IconButton className={classes.addButton} onClick={handleAddAlertClick} ref={buttonRef}>
                                            <AddAlertIcon />
                                        </IconButton>
                                        <Popover
                                            open={alertOpen}
                                            anchorEl={anchorEl}
                                            onClose={handleAddAlertClose}
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "right",
                                            }}
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                            }}
                                        >
                                            <div className={classes.popOver}>
                                                <div className={classes.contentPopOver}>
                                                    <TextField
                                                        id="alertAmt"
                                                        type="number"
                                                        value={amt}
                                                        onChange={(e) => setAmt(e.target.value)}
                                                        placeholder="days/hours"
                                                        margin="normal"
                                                        variant="outlined"
                                                    />
                                                </div>
                                                <div>
                                                    <FormControl>
                                                        <RadioGroup value={metric} onChange={(e) => setMetric(e.target.value)} className={classes.radioButton}>
                                                            <div>
                                                                <FormControlLabel
                                                                    value={caseActionReminderMetrics.DAYSBEFORE.tag}
                                                                    control={<Radio />}
                                                                    label={caseActionReminderMetrics.DAYSBEFORE.radioLabel}
                                                                />
                                                            </div>
                                                            <div>
                                                                <FormControlLabel
                                                                    value={caseActionReminderMetrics.HOURSBEFORE.tag}
                                                                    control={<Radio />}
                                                                    label={caseActionReminderMetrics.HOURSBEFORE.radioLabel}
                                                                />
                                                            </div>
                                                        </RadioGroup>
                                                    </FormControl>
                                                </div>
                                                <Box display="flex" justifyContent="flex-end">
                                                    <Button
                                                        onClick={addAlert}
                                                        //style={{ marginLeft: "30px" }}
                                                        variant="text"
                                                        color="primary"
                                                    >
                                                        Add
                                                    </Button>
                                                </Box>
                                            </div>
                                        </Popover>
                                    </Box>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={3}>
                            <Typography>Adviser(s):</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Autocomplete
                                multiple
                                options={filteredAdvisers.filter((u) => u.isActive)}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                value={advisers || []}
                                onChange={onAdvisersChange}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox className={classes.checkboxSelect} icon={uncheckedIcon} checkedIcon={checkedIcon} checked={selected} />
                                        {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            className={classes.textField}
                                            variant="outlined"
                                            size="small"
                                            placeholder={!params.InputProps?.startAdornment ? "Advisers" : ""}
                                            fullWidth
                                        />
                                    );
                                }}
                                ChipProps={{
                                    className: classes.selectedChip,
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={3}></Grid>
                        <Grid item xs={9}>
                            <Typography>Or</Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={3}>
                            <Typography>Team:</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            {isTeamsLoading && <CircularProgress />}
                            {isTeamsError && <Typography>{teamsError?.message || "Failed to load teams"}</Typography>}
                            <FormControl required={!action.advisers?.length} className={classes.fullWidthField} variant="outlined">
                                <Select
                                    value={action.teamId || ""}
                                    onChange={(e) =>
                                        setAction({
                                            ...action,
                                            adviserId: "",
                                            advisers: [],
                                            teamId: e.target.value,
                                        })
                                    }
                                    displayEmpty
                                    data-cy="select_team"
                                >
                                    <MenuItem value="" disabled>
                                        Select team
                                    </MenuItem>
                                    {filteredTeams.map((t) => (
                                        <MenuItem key={t.teamId} value={t.teamId} data-cy={`team-${t.teamId}`}>
                                            {t.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={3}>
                            <Typography>Details:</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                variant="outlined"
                                value={action.details || ""}
                                onChange={(e) =>
                                    setAction({
                                        ...action,
                                        details: e.target.value,
                                    })
                                }
                                className={classes.fullWidthField}
                                multiline
                                rows={4}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.bottomButtons}>
                    {isAddCaseActionLoading || isUpdateCaseActionLoading ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <Button onClick={handleClose} color="default" variant="outlined">
                                Cancel
                            </Button>
                            {action.id ? (
                                <>
                                    <Button type="submit" color="primary" variant="contained">
                                        Save & Exit
                                    </Button>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        name="complete"
                                        className={classes.completeButton}
                                        data-cy="mark_as_complete"
                                    >
                                        Mark as Complete
                                    </Button>
                                </>
                            ) : (
                                <Button type="submit" color="primary" variant="contained" data-cy="add_action_save">
                                    Save
                                </Button>
                            )}
                        </>
                    )}
                </DialogActions>
            </form>
        </Dialog>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
    };
};

export default connect(null, mapDispatchToProps)(CaseActionDialog);
