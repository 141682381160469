export const SET_USER_ADVICE_TYPES = "SET_USER_ADVICE_TYPES";
export const SET_CURRENT_CASE = "SET_CURRENT_CASE";
export const REMOVE_CASE_TAB = "REMOVE_CASE_TAB";
export const SET_CASE_VIEWSTATE = "SET_CASE_VIEWSTATE";
export const SET_CASE_VIEWSTATE_ACTIVITY_MANAGE = "SET_CASE_VIEWSTATE_ACTIVITY_MANAGE";
export const ADD_CASE = "ADD_CASE";
export const SET_ADVISERID = "SET_ADVISERID";
export const SET_CASE_TYPE_AND_STAGE = "SET_CASE_TYPE_AND_STAGE";
export const SET_CASE_CONTACTS = "SET_CASE_CONTACTS";
export const SET_CASE_ADVISERS = "SET_CASE_ADVISERS";
export const SET_CASE_CLOSED = "SET_CASE_CLOSED";
export const SET_CASE_PINNED = "SET_CASE_PINNED";
export const SET_CASE_OUTCOME_ID = "SET_CASE_OUTCOME_ID";
export const SET_REPLYING_TO = "SET_REPLYING_TO";
export const SET_REPLY_EDITOR_HTML = "SET_REPLY_EDITOR_HTML";
export const SET_REPLY_FROM_ADDRESS = "SET_REPLY_FROM_ADDRESS";
export const SET_REPLY_CONTACTS = "SET_REPLY_CONTACTS";
export const SET_REPLY_NON_CONTACTS = "SET_REPLY_NON_CONTACTS";
export const SET_REPLY_INT_CCS = "SET_REPLY_INT_CCS";
export const SET_REPLY_CC_NON_CONTACTS = "SET_REPLY_CC_NON_CONTACTS";
export const SET_PERMISSION_USERS = "SET_PERMISSION_USERS";
export const SET_CASE_PERMISSION_TYPE = "SET_CASE_PERMISSION_TYPE";
export const SET_CASE_EMPLOYEES = "SET_CASE_EMPLOYEES";
export const SET_CASE_ACTIVITY_DESCRIPTION = "SET_CASE_ACTIVITY_DESCRIPTION";
export const ADD_CASE_EVENT = "ADD_CASE_EVENT";
export const ADD_CASE_EVENTS = "ADD_CASE_EVENTS";
export const UPDATE_CASE_EVENT_CHARGEABLE = "UPDATE_CASE_EVENT_CHARGEABLE";
export const UPDATE_CASE_CHARGEABLE_TIME = "UPDATE_CASE_CHARGEABLE_TIME";
export const ADD_REPLY_PRECEDENT = "ADD_REPLY_PRECEDENT";
export const ADD_REPLY_ATTACHMENT = "ADD_REPLY_ATTACHMENT";
export const REMOVE_REPLY_PRECEDENT = "REMOVE_REPLY_PRECEDENT";
export const REMOVE_REPLY_PRECEDENTS = "REMOVE_REPLY_PRECEDENTS";
export const UPDATE_CASE_PRECEDENT_NAME = "UPDATE_CASE_PRECEDENT_NAME";
export const REMOVE_REPLY_ATTACHMENT = "REMOVE_REPLY_ATTACHMENT";
export const SET_PRECEDENT_DOCUMENT_HTML = "SET_PRECEDENT_DOCUMENT_HTML";
export const ADD_CLAIM = "ADD_CLAIM";
export const DELETE_CLAIM = "DELETE_CLAIM";
export const UPDATE_CLAIM = "UPDATE_CLAIM";
export const SET_CLAIMS = "SET_CLAIMS";
export const UPDATE_ET_CASE = "UPDATE_ET_CASE";
export const SET_COMPLETED_STAGE_TASKS = "SET_COMPLETED_STAGE_TASKS";
export const SET_CASE_MINUTES = "SET_CASE_MINUTES";
export const SET_REPLY_ATTACHMENTS = "SET_REPLY_ATTACHMENTS";
export const SET_REPLY_ATTACHMENT_AZURE_FILE_IDS = "SET_REPLY_ATTACHMENT_AZURE_FILE_IDS";
export const SET_REPLY_ATTACHMENTS_LINKED = "SET_REPLY_ATTACHMENTS_LINKED";
export const UNASSIGN_ACTIVITY = "UNASSIGN_ACTIVITY";
export const SET_TIME_EVENT = "SET_TIME_EVENT";
export const ADD_TIME_EVENT = "ADD_TIME_EVENT";
export const CLEAR_TIME_EVENTS = "CLEAR_TIME_EVENTS";
export const SET_CASE_WINDOW_STATUS = "SET_CASE_WINDOW_STATUS";
export const SET_FLEX_WINDOW_STATUS = "SET_FLEX_WINDOW_STATUS";
export const SET_CALL_NOTE_ISSUE_FOCUS = "SET_CALL_NOTE_ISSUE_FOCUS";
export const SET_CALL_NOTE_ADVICE_FOCUS = "SET_CALL_NOTE_ADVICE_FOCUS";
export const SET_CALL_NOTE_ACTION_FOCUS = "SET_CALL_NOTE_ACTION_FOCUS";
export const SET_CASE_TIMER_RUNNING = "SET_CASE_TIMER_RUNNING";
export const SET_CALL_NOTE_CALL = "SET_CALL_NOTE_CALL";
export const SET_CALL_NOTE_CHAT = "SET_CALL_NOTE_CHAT";
export const SET_CALL_NOTE_TEXT = "SET_CALL_NOTE_TEXT";
export const SET_CALL_NOTE_CASE_ACTIVITY_ID = "SET_CALL_NOTE_CASE_ACTIVITY_ID";
export const UPDATE_CALL_NOTE = "UPDATE_CALL_NOTE";
export const SET_CALL_NOTE_DESCRIPTION = "SET_CALL_NOTE_DESCRIPTION";
export const SET_CALL_NOTE_DESCRIPTION_FOCUS = "SET_CALL_NOTE_DESCRIPTION_FOCUS";
export const SET_REPLY_SUBJECT = "SET_REPLY_SUBJECT";
export const SET_REPLY_DESCRIPTION = "SET_REPLY_DESCRIPTION";
export const SET_REPLY_PRECEDENTS = "SET_REPLY_PRECEDENTS";
export const SET_CASE_CONTRACT = "SET_CASE_CONTRACT";
export const SET_INTERNAL_NOTE_TEXT = "SET_INTERNAL_NOTE_TEXT";
export const SET_INTERNAL_NOTE_FOCUS = "SET_INTERNAL_NOTE_FOCUS";
export const UPDATE_INTERNAL_NOTE = "UPDATE_INTERNAL_NOTE";
export const SET_INTERNAL_NOTE_CASE_ACTIVITY_ID = "SET_INTERNAL_NOTE_CASE_ACTIVITY_ID";
export const SET_INTERNAL_NOTE_DESCRIPTION = "SET_INTERNAL_NOTE_DESCRIPTION";
export const SET_INTERNAL_NOTE_DESCRIPTION_FOCUS = "SET_INTERNAL_NOTE_DESCRIPTION_FOCUS";
export const SET_CASE_DESCRIPTION = "SET_CASE_DESCRIPTION";
export const SET_CALL_NOTE_MODE = "SET_CALL_NOTE_MODE";
export const SET_CALL_NOTE_CONTACT = "SET_CALL_NOTE_CONTACT";
export const SET_CALL_NOTE_OUTBOUND = "SET_CALL_NOTE_OUTBOUND";
export const SET_EMAIL = "SET_EMAIL";
export const SET_EMAILS = "SET_EMAILS";
export const ADD_ACCEPTED_EMAIL_TASK_SID = "ADD_ACCEPTED_EMAIL_TASK_SID";
export const REMOVE_ACCEPTED_EMAIL_TASK_SID = "REMOVE_ACCEPTED_EMAIL_TASK_SID";
export const SET_ACTIVE_FLEX_EMAIL_TASK_SID = "SET_ACTIVE_FLEX_EMAIL_TASK_SID";
export const SET_CASE_ACTIVITY_IDS = "SET_CASE_ACTIVITY_IDS";
export const INCREMENT_EVENT_COUNT = "INCREMENT_EVENT_COUNT";
export const ADD_REPLY_CC = "ADD_REPLY_CC";
export const REMOVE_REPLY_INT_CC = "REMOVE_REPLY_INT_CC";
export const REMOVE_REPLY_CONTACT_NOT_KNOWN = "REMOVE_REPLY_CONTACT_NOT_KNOWN";
export const ADD_REPLY_CONTACT_NOT_KNOWN = "ADD_REPLY_CONTACT_NOT_KNOWN";
export const REMOVE_REPLY_CC_CONTACT_NOT_KNOWN = "REMOVE_REPLY_CC_CONTACT_NOT_KNOWN";
export const ADD_REPLY_NON_CONTACT_CC = "ADD_REPLY_NON_CONTACT_CC";
export const SET_REPLY_CC_CONTACTS = "SET_REPLY_CC_CONTACTS";
export const SET_REPLY_BCC_CONTACTS = "SET_REPLY_BCC_CONTACTS";
export const ADD_REPLY_BCC = "ADD_REPLY_BCC";
export const REMOVE_REPLY_BCC = "REMOVE_REPLY_INT_BCC";
export const ADD_REPLY_NON_CONTACT_BCC = "ADD_REPLY_NON_CONTACT_BCC";
export const REMOVE_REPLY_NON_CONTACT_BCC = "REMOVE_REPLY_NON_CONTACT_BCC";
export const SET_REPLY_TO_INDEX = "SET_REPLY_TO_INDEX";
export const SET_CALL_CONTACT_TO_INDEX = "SET_CALL_CONTACT_TO_INDEX";
export const SET_EDITING_REPLY_CHAIN = "SET_EDITING_REPLY_CHAIN";
export const REASSIGN_CASE_ACTIVITY = "REASSIGN_CASE_ACTIVITY";
export const SET_ADJUST_CASE_MINUTES = "SET_ADJUST_CASE_MINUTES";
export const SET_CASE_HISTORY_MODE = "SET_CASE_HISTORY_MODE";
export const SET_CASE_HISTORY_CHECK_ALL = "SET_CASE_HISTORY_CHECK_ALL";
export const SET_DOWNLOAD_CASE_ACTIVITY_IDS = "SET_DOWNLOAD_CASE_ACTIVITY_IDS";
export const SET_CASE_ACTIVITY_DURATION = "SET_CASE_ACTIVITY_DURATION";
export const SET_CASE_VISIBLE_TO_CONTACTS_ONLY = "SET_CASE_VISIBLE_TO_CONTACTS_ONLY";
export const SET_CASE_ATTACHMENTS_SELECTED = "SET_CASE_ATTACHMENTS_SELECTED";
export const SET_DRAFT_ATTACHMENTS_SELECTED = "SET_DRAFT_ATTACHMENTS_SELECTED";
export const SET_CASE_IS_SEARCHING = "SET_CASE_IS_SEARCHING";
export const SET_CASE_FILES_FOR_DOWNLOAD = "SET_CASE_FILES_FOR_DOWNLOAD";

export const SET_CASE_AI_STATE = "SET_CASE_AI_STATE";

export const SET_DISPLAY_CASE_SEARCH_IN_TAB = "SET_DISPLAY_CASE_SEARCH_IN_TAB";
export const SET_DISPLAY_CLOSED_CASES = "SET_DISPLAY_CLOSED_CASES";
export const SET_CRITERIA_ACCOUNT_NAME = "SET_CRITERIA_ACCOUNT_NAME";
export const SET_CRITERIA_PARTNER_NUMBER = "SET_CRITERIA_PARTNER_NUMBER";
export const SET_CRITERIA_EMPLOYEE = "SET_CRITERIA_EMPLOYEE";
export const SET_CRITERIA_PARTNERSHIP_ID = "SET_CRITERIA_PARTNERSHIP_ID";
export const SET_CRITERIA_ADVISER_ID = "SET_CRITERIA_ADVISER_ID";
export const SET_CRITERIA_CASE_TYPE_ID = "SET_CRITERIA_CASE_TYPE_ID";
export const SET_CRITERIA_MODIFIED_DATE_FROM = "SET_CRITERIA_MODIFIED_DATE_FROM";
export const SET_CRITERIA_MODIFIED_DATE_TO = "SET_CRITERIA_MODIFIED_DATE_TO";
export const SET_CRITERIA_CREATED_DATE_FROM = "SET_CRITERIA_CREATED_DATE_FROM";
export const SET_CRITERIA_CREATED_DATE_TO = "SET_CRITERIA_CREATED_DATE_TO";
export const SET_CRITERIA_PRIMARY_CONTACT = "SET_CRITERIA_PRIMARY_CONTACT";
export const SET_CRITERIA_CASE_DESCRIPTION = "SET_CRITERIA_CASE_DESCRIPTION";

export const SET_CASE_TYPES = "SET_CASE_TYPES";
export const SET_CONFIG_INFO = "SET_CONFIG_INFO";
export const SET_SERVER_VALUES = "SET_SERVER_VALUES";
export const SET_PRECEDENT_INFO = "SET_PRECEDENT_INFO";
export const SET_PRECEDENT_FOLDER = "SET_PRECEDENT_FOLDER";
export const SET_PRECEDENT_FOLDERS = "SET_PRECEDENT_FOLDERS";
export const SET_PRECEDENT_DISABLED_FLAG = "SET_PRECEDENT_DISABLED_FLAG";
export const SET_PRECEDENT_FOLDER_DISABLED_FLAG = "SET_PRECEDENT_FOLDER_DISABLED_FLAG";
export const SET_PRECEDENTS = "SET_PRECEDENTS";
export const SET_PRECEDENT_IS_ADVISOR = "SET_PRECEDENT_IS_ADVISOR";
export const SET_PRECEDENT_RESOURCE_LICENCES = "SET_PRECEDENT_RESOURCE_LICENCES";

export const SET_CLAIM_TYPES = "SET_CLAIM_TYPES";
export const SET_DISCRIMINATION_TYPES = "SET_DISCRIMINATION_TYPES";
export const SET_VENUES = "SET_VENUES";
export const SET_CASE_STAGES = "SET_CASE_STAGES";
export const UPSERT_CASE_TYPES = "UPSERT_CASE_TYPES";
export const DELETE_CASE_TYPE = "DELETE_CASE_TYPE";

export const SET_STAGE_TASKS = "SET_STAGE_TASKS";
export const SET_MENTIONS = "SET_MENTIONS";
export const SET_PARTNERSHIPS = "SET_PARTNERSHIPS";

export const SET_EMAIL_EXCEPTION_FILTERS = "SET_EMAIL_EXCEPTION_FILTERS";

export const SET_SNACK = "SET_SNACK";
export const REMOVE_SNACK = "REMOVE_SNACK";

export const SET_THEME = "SET_THEME";

export const SET_USER = "SET_USER";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_USERS = "SET_USERS";
export const UPDATE_USER_MANAGER = "UPDATE_USER_MANAGER";
export const SET_CHECKING_USER_SESSION = "SET_CHECKING_USER_SESSION";

export const SET_TEMPLATE = "SET_TEMPLATE";
export const SET_USER_TIMER_RUNNING = "SET_USER_TIMER_RUNNING";

export const DIAL = "DIAL";
export const SET_FLEX_ACTIVITY = "SET_FLEX_ACTIVITY";
export const SET_IN_CALL = "SET_IN_CALL";
export const SET_CALL_START = "SET_CALL_START";
export const SET_CALL_END = "SET_CALL_END";
export const SET_PENDING_EMAIL = "SET_PENDING_EMAIL";
export const ADD_CALL_RESERVATION = "ADD_CALL_RESERVATION";
export const SET_CURRENT_CALL_RESERVATION_SID = "SET_CURRENT_CALL_RESERVATION_SID";
export const CLEAR_UNALLOCATED_CALLS = "CLEAR_UNALLOCATED_CALLS";
export const SET_LAST_LOGGED_CALL_RESERVATION_SID = "SET_LAST_LOGGED_CALL_RESERVATION_SID";

export const SET_ACCOUNT_NOTE = "SET_ACCOUNT_NOTE";
export const SET_DISPLAY_ACCOUNT_SEARCH_IN_TAB = "SET_DISPLAY_ACCOUNT_SEARCH_IN_TAB";
export const SET_CURRENT_ACCOUNT = "SET_CURRENT_ACCOUNT";
export const REMOVE_ACCOUNT_TAB = "REMOVE_ACCOUNT_TAB";
export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const SET_ACC_CRITERIA_ACCOUNT_NAME = "SET_ACC_CRITERIA_ACCOUNT_NAME";
export const SET_ACC_CRITERIA_POST_CODE = "SET_ACC_CRITERIA_POST_CODE";
export const SET_ACC_CRITERIA_PARTNER_NUMBER = "SET_ACC_CRITERIA_PARTNER_NUMBER";
export const SET_ACC_CRITERIA_PARTNERSHIP_ID = "SET_ACC_CRITERIA_PARTNERSHIP_ID";
export const SET_ACC_CRITERIA_ACCOUNT_STATUS = "SET_ACC_CRITERIA_ACCOUNT_STATUS";
export const SET_ACCOUNT_NOTE_EDITED = "SET_ACCOUNT_NOTE_EDITED";
export const SET_ACC_CRITERIA_ADVISER_ID = "SET_ACC_CRITERIA_ADVISER_ID";

export const SET_CREATE_CASE_ACCOUNT = "SET_CREATE_CASE_ACCOUNT";
export const SET_CREATE_CASE_CONTACT = "SET_CREATE_CASE_CONTACT";
export const SET_CREATE_CASE_ACCOUNT_AND_CONTACT = "SET_CREATE_CASE_ACCOUNT_AND_CONTACT";
export const RESET_CREATE_CASE = "RESET_CREATE_CASE";

export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";

export const OPEN_FILE_VIEWER = "OPEN_FILE_VIEWER";
export const CLOSE_FILE_VIEWER = "CLOSE_FILE_VIEWER";

export const SET_CREATE_CONTACT_PHONE = "SET_CREATE_CONTACT_PHONE";
export const SET_CREATE_CONTACT_EMAIL = "SET_CREATE_CONTACT_EMAIL";
export const SET_CREATE_CONTACT_ACCOUNT = "SET_CREATE_CONTACT_ACCOUNT";
export const SET_CREATE_CONTACT_ACTIVE_STEP = "SET_CREATE_CONTACT_ACTIVE_STEP";
export const RESET_CREATE_CONTACT = "RESET_CREATE_CONTACT";

export const SET_CONFIRM_COMPLETE_TASK_INFO = "SET_CONFIRM_COMPLETE_TASK_INFO";

export const SET_FULL_SCREEN_EMAIL = "SET_FULL_SCREEN_EMAIL";
export const SHOW_CASE_ACTION_DETAILS = "SHOW_CASE_ACTION_DETAILS";

export const STOP_CASE_TIMER = "STOP_CASE_TIMER";
export const PAUSE_CASE_TIMER = "PAUSE_CASE_TIMER";
export const RESUME_CASE_TIMER = "RESUME_CASE_TIMER";
export const START_TIMING_CASE_ACTIVITY = "START_TIMING_CASE_ACTIVITY";
export const ADD_UNALLOCATED_CASE_ACTIVITIES = "ADD_UNALLOCATED_CASE_ACTIVITIES";
export const FILTER_SAVED_CASE_ACTIVITIES = "FILTER_SAVED_CASE_ACTIVITIES";
export const SET_REQUEST_STATUS = "SET_REQUEST_STATUS";

export const RESET_MEETING = "RESET_MEETING";
export const SET_MEETING = "SET_MEETING";
export const SET_MEETING_FIELD = "SET_MEETING_FIELD";
export const UPDATE_MEETING_ACTIVITY = "UPDATE_MEETING_ACTIVITY";

export const SET_MISC_CASE_ACTIVITY_ID = "SET_MISC_CASE_ACTIVITY_ID";
export const SET_MISC_ACTIVITY_DESCRIPTION = "SET_MISC_ACTIVITY_DESCRIPTION";
export const SET_MISC_ACTIVITY_TYPE_ID = "SET_MISC_ACTIVITY_TYPE_ID";
export const SET_MISC_ACTIVITY_DATE = "SET_MISC_ACTIVITY_DATE";
export const SET_MISC_ACTIVITY_START_TIME = "SET_MISC_ACTIVITY_START_TIME";
export const UPDATE_RETROSPECTIVE_MISC_ACTIVITY = "UPDATE_RETROSPECTIVE_MISC_ACTIVITY";
export const UPDATE_CURRENT_MISC_ACTIVITY = "UPDATE_CURRENT_MISC_ACTIVITY";
export const SET_EXISTING_MISC_ACTIVITY_FILES = "SET_EXISTING_MISC_ACTIVITY_FILES";
export const SET_NEW_MISC_ACTIVITY_FILES = "SET_NEW_MISC_ACTIVITY_FILES";
export const SET_MISC_ACTIVITY_FILES_TO_DELETE = "SET_MISC_ACTIVITY_FILES_TO_DELETE";
export const SET_MISC_ACTIVITY_HOURS = "SET_MISC_ACTIVITY_HOURS";
export const SET_MISC_ACTIVITY_MINUTES = "SET_MISC_ACTIVITY_MINUTES";
export const SET_MISC_ACTIVITY_NOTES = "SET_MISC_ACTIVITY_NOTES";

export const SET_CALL_NOTE_INTERACTED = "SET_CALL_NOTE_INTERACTED";
export const QUEUE_BLUR = "QUEUE_BLUR";

export const SET_BACKDROP_OPEN = "SET_BACKDROP_OPEN";

export const UPDATE_CASE_DRAFT_EMAIL = "UPDATE_CASE_DRAFT_EMAIL";
export const CLEAR_CASE_DRAFT_EMAIL = "CLEAR_CASE_DRAFT_EMAIL";
export const SET_EDITING_DRAFT = "SET_EDITING_DRAFT";

export const SET_SHOW_EDITOR = "SET_SHOW_EDITOR";

export const SET_CASE_RESEARCH_OPEN = "SET_CASE_RESEARCH_OPEN";
export const SET_CASE_RESEARCH_SESSION_ID = "SET_CASE_RESEARCH_SESSION_ID";
export const INCREMENT_CASE_RESEARCH_SECONDS = "INCREMENT_CASE_RESEARCH_SECONDS";
export const SET_EXISTING_CASE_RESEARCH_SESSION = "SET_EXISTING_CASE_RESEARCH_SESSION";
export const SET_CASE_RESEARCH_START = "SET_CASE_RESEARCH_START";

export const SET_CONFIRM_CLOSE_OPEN = "SET_CONFIRM_CLOSE_OPEN";

export const SET_CASE_CHARGEABLE = "SET_CASE_CHARGEABLE";

export const SET_CASE_FILES_TREE_CHANGED = "SET_CASE_FILES_TREE_CHANGED";

// case actions requiring case time events to be saved to API

// sending an email
export const SEND_EMAIL_REQUEST = "SEND_EMAIL_REQUEST";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAIL = "SEND_EMAIL_FAIL";

// adding a call and note
export const ADD_CALL_AND_NOTE_REQUEST = "ADD_CALL_AND_NOTE_REQUEST";
export const ADD_CALL_AND_NOTE_SUCCESS = "ADD_CALL_AND_NOTE_SUCCESS";
export const ADD_CALL_AND_NOTE_FAIL = "ADD_CALL_AND_NOTE_FAIL";

// adding a call
export const ADD_CALL_REQUEST = "ADD_CALL_REQUEST";
export const ADD_CALL_SUCCESS = "ADD_CALL_SUCCESS";
export const ADD_CALL_FAIL = "ADD_CALL_FAIL";

// updating a note
export const UPDATE_NOTE_REQUEST = "UPDATE_NOTE_REQUEST";
export const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS";
export const UPDATE_NOTE_FAIL = "UPDATE_NOTE_FAIL";

// saving an internal note
export const SAVE_INTERNAL_NOTE_REQUEST = "SAVE_INTERNAL_NOTE_REQUEST";
export const SAVE_INTERNAL_NOTE_SUCCESS = "SAVE_INTERNAL_NOTE_SUCCESS";
export const SAVE_INTERNAL_NOTE_FAIL = "SAVE_INTERNAL_NOTE_FAIL";

// saving an email draft
export const SAVE_DRAFT_REQUEST = "SAVE_DRAFT_REQUEST";
export const SAVE_DRAFT_SUCCESS = "SAVE_DRAFT_SUCCESS";
export const SAVE_DRAFT_FAIL = "SAVE_DRAFT_FAIL";

// discarding unallocated case time
export const DISCARD_UNALLOCATED_TIME_REQUEST = "DISCARD_UNALLOCATED_TIME_REQUEST";
export const DISCARD_UNALLOCATED_TIME_SUCCESS = "DISCARD_UNALLOCATED_TIME_SUCCESS";
export const DISCARD_UNALLOCATED_TIME_FAIL = "DISCARD_UNALLOCATED_TIME_FAIL";
export const SET_PROCESS_UNALLOCATED_TIME_REQUEST_STATUS = "SET_PROCESS_UNALLOCATED_TIME_REQUEST_STATUS";

// assigning unallocated case time to user activity
export const ASSIGN_UNALLOCATED_TIME_TO_USER_ACTIVITY_REQUEST = "ASSIGN_UNALLOCATED_TIME_TO_USER_ACTIVITY_REQUEST";
export const ASSIGN_UNALLOCATED_TIME_TO_USER_ACTIVITY_SUCCESS = "ASSIGN_UNALLOCATED_TIME_TO_USER_ACTIVITY_SUCCESS";
export const ASSIGN_UNALLOCATED_TIME_TO_USER_ACTIVITY_FAIL = "ASSIGN_UNALLOCATED_TIME_TO_USER_ACTIVITY_FAIL";

// assigning unallocated case time to misc activity
export const ASSIGN_UNALLOCATED_TIME_TO_MISC_ACTIVITY_REQUEST = "ASSIGN_UNALLOCATED_TIME_TO_MISC_ACTIVITY_REQUEST";
export const ASSIGN_UNALLOCATED_TIME_TO_MISC_ACTIVITY_SUCCESS = "ASSIGN_UNALLOCATED_TIME_TO_MISC_ACTIVITY_SUCCESS";
export const ASSIGN_UNALLOCATED_TIME_TO_MISC_ACTIVITY_FAIL = "ASSIGN_UNALLOCATED_TIME_TO_MISC_ACTIVITY_FAIL";

// holding unallocated case time
export const HOLD_UNALLOCATED_TIME_REQUEST = "HOLD_UNALLOCATED_TIME_REQUEST";
export const HOLD_UNALLOCATED_TIME_SUCCESS = "HOLD_UNALLOCATED_TIME_SUCCESS";
export const HOLD_UNALLOCATED_TIME_FAIL = "HOLD_UNALLOCATED_TIME_FAIL";

// get unallocated case time
export const GET_UNALLOCATED_TIME_REQUEST = "GET_UNALLOCATED_TIME_REQUEST";
export const GET_UNALLOCATED_TIME_SUCCESS = "GET_UNALLOCATED_TIME_SUCCESS";
export const GET_UNALLOCATED_TIME_FAIL = "GET_UNALLOCATED_TIME_FAIL";
export const SET_GET_UNALLOCATED_TIME_REQUEST_STATUS = "SET_GET_UNALLOCATED_TIME_REQUEST_STATUS";
export const SET_UNALLOCATED_SECONDS = "SET_UNALLOCATED_SECONDS";
export const SET_UNALLOCATED_CASE_TIME_EVENT_IDS = "SET_UNALLOCATED_CASE_TIME_EVENT_IDS";

// save every 60 seconds
export const SAVE_TIMER_EXPIRED_REQUEST = "SAVE_TIMER_EXPIRED_REQUEST";
export const SAVE_TIMER_EXPIRED_SUCCESS = "SAVE_TIMER_EXPIRED_SUCCESS";
export const SAVE_TIMER_EXPIRED_FAIL = "SAVE_TIMER_EXPIRED_FAIL";

// callback request
export const SET_CALLBACK_REQUEST_DATA = "SET_CALLBACK_REQUEST_DATA";
export const SET_CALLBACK_REQUEST_OPEN = "SET_CALLBACK_REQUEST_OPEN";

// add current misc activity
export const ADD_CURRENT_MISC_ACTIVITY_REQUEST = "ADD_CURRENT_MISC_ACTIVITY_REQUEST";
export const ADD_CURRENT_MISC_ACTIVITY_SUCCESS = "ADD_CURRENT_MISC_ACTIVITY_SUCCESS";
export const ADD_CURRENT_MISC_ACTIVITY_FAIL = "ADD_CURRENT_MISC_ACTIVITY_FAIL";

export const SET_MISC_ACTIVITY_TAB = "SET_MISC_ACTIVITY_TAB";
export const RESET_MISC_ACTIVITY = "RESET_MISC_ACTIVITY";
export const RESET_CURRENT_MISC_ACTIVITY = "RESET_CURRENT_MISC_ACTIVITY";
export const SET_CURRENT_MISC_ACTIVITY_OPEN = "SET_CURRENT_MISC_ACTIVITY_OPEN";

// misc activity attachments
export const SET_MISC_ACTIVITY_ATTACHMENTS = "SET_MISC_ACTIVITY_ATTACHMENTS";
export const SET_MISC_ACTIVITY_DRAFT_ATTACHMENTS_SELECTED = "SET_MISC_ACTIVITY_DRAFT_ATTACHMENTS_SELECTED";

// KNOWLEDGE
export const SET_KNOWLEDGE_CATEGORIES = "SET_KNOWLEDGE_CATEGORIES";
export const SET_KNOWLEDGE_ARTICLES = "SET_KNOWLEDGE_ARTICLES";
export const SET_KNOWLEDGE_DOCUMENTS = "SET_KNOWLEDGE_DOCUMENTS";
export const UPDATE_ARTICLE = "UPDATE_ARTICLE";

// case history filters
export const SET_CASE_HISTORY_DATE_FROM = "SET_CASE_HISTORY_DATE_FROM";
export const SET_CASE_HISTORY_DATE_TO = "SET_CASE_HISTORY_DATE_TO";
export const RESET_CASE_HISTORY_FILTERS = "RESET_CASE_HISTORY_FILTERS";
export const SET_CASE_HISTORY_ACTIVITY = "SET_CASE_HISTORY_ACTIVITY";
export const REMOVE_CASE_HISTORY_ACTIVITY = "REMOVE_CASE_HISTORY_ACTIVITY";
export const SET_CASE_HISTORY_CHARGEABLE = "SET_CASE_HISTORY_CHARGEABLE";
export const REMOVE_CASE_HISTORY_CHARGEABLE = "REMOVE_CASE_HISTORY_CHARGEABLE";
export const SET_CASE_HISTORY_DIRECTION = "SET_CASE_HISTORY_DIRECTION";
export const REMOVE_CASE_HISTORY_DIRECTION = "REMOVE_CASE_HISTORY_DIRECTION";
export const SET_CASE_HISTORY_USER = "SET_CASE_HISTORY_USER";
export const REMOVE_CASE_HISTORY_USER = "REMOVE_CASE_HISTORY_USER";
export const SET_MISC_ACTIVITY_UPLOAD_STATUS = "SET_MISC_ACTIVITY_UPLOAD_STATUS";
export const SET_REPLY_UPLOAD_STATUS = "SET_REPLY_UPLOAD_STATUS";
export const CLEAR_NON_PRECEDENT_ATTACHMENTS = "CLEAR_NON_PRECEDENT_ATTACHMENTS";
export const SET_SEEK_ADVICE_MISC_ACTIVITY_ID = "SET_SEEK_ADVICE_MISC_ACTIVITY_ID";
export const SET_RAISE_CASE_REQUEST_ID = "SET_RAISE_CASE_REQUEST_ID";
export const SET_PENDING_RAISE_CASE_REQUEST = "SET_PENDING_RAISE_CASE_REQUEST";