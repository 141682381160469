import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import axios from "../plugins/axios";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    CircularProgress,
} from "@material-ui/core";
import { showCaseActionDetails } from "../redux/actions/userActions";
import { setSnackAction } from "../redux/actions/snackActions";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const CaseActionDetailsDialog = ({ caseActionId }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);   
    const [details, setDetails] = useState();

    const handleClose = () => {
        dispatch(showCaseActionDetails(null));
    };        

    useEffect(() => {

        const getDetails = async () => {

            setLoading(true);
            setDetails();

            let response = await axios.get(`${apiUrl}/getcaseactiondetails/${caseActionId}`);

            setLoading(false);

            if (response.data) {
                setDetails(response.data);
            } else {
                dispatch(setSnackAction("Couldn't retrieve the case action. Please try again. If problem persists contact a member of IT support", "error"));
                dispatch(showCaseActionDetails(null));
            }
        }

        if (caseActionId)
            getDetails();
        
    }, [caseActionId, dispatch]);

    if (!caseActionId)
        return null;  

    return (
        <React.Fragment>
            <Dialog onClose={handleClose} open={!!caseActionId} fullWidth maxWidth={"md"}>
                <DialogTitle>Case Action Details</DialogTitle>
                <DialogContent>
                    {loading
                        ? <Box display="flex" width="100%" justifyContent="center">
                            <CircularProgress />
                        </Box>
                        : <pre>{details}</pre>
                    }
                </DialogContent>
            </Dialog>         
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    caseActionId: state.userReducer.displayedCaseActionId,    
});

export default connect(mapStateToProps)(CaseActionDetailsDialog);